import React, { memo } from "react";
import Header from "../header/header";
import CompanyListSidebar from "../companyListSidebar/CompanyListSidebar";
import "./layout.scss";
import Sidebar from "../Sidebar/sidebar";
import Router from "../../routes";
import { Row, Col } from "antd";

const Layout = () => {
  return (
    <div className="layout_container">
      <div className="layout_header_container">
        <Header />
      </div>
      <div className="layout_route_container">
        <Row className="sideBar-container">
          <Col span={8}>
            <CompanyListSidebar />
          </Col>
          <Col span={16}>
            <Sidebar />
          </Col>
        </Row>
        <div
          className={`route-container ${
            window.location.href.includes("/batches/batch?id")
              ? "route_cont"
              : ""
          }`}
        >
          <Router />
        </div>
      </div>
    </div>
  );
};

export default memo(Layout);
