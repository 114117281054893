import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushSkusDetails } from "../ReduxComponents/Reducers/SkusReducer";
import { pushBatchDetails } from "../ReduxComponents/Reducers/BatchReducer";

const GroupByFilter = ({ type }) => {
  const {
    skusReducer: { groupBy },
    batchReducer: { batchGroupBy },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleGroupValue = (val) => {
    console.log('changes');
    localStorage.removeItem('batchScrollId');
        localStorage.removeItem('skuScrollId');
    let tempObj = JSON.parse(localStorage.getItem("groupBy")) ?? {};
    switch (type) {
      case "sku":
        {
          localStorage.setItem(
            "groupBy",
            JSON.stringify({
              ...tempObj,
              sku: !!val ? val : null,
            })
          );
          dispatch(
            pushSkusDetails({
              groupBy: !!val ? val : null,
            })
          );
        }
        break;

      case "batch":
        {
          localStorage.setItem(
            "groupBy",
            JSON.stringify({
              ...tempObj,
              batch: !!val ? val : null,
            })
          );
          dispatch(
            pushBatchDetails({
              batchGroupBy: !!val ? val : null,
            })
          );
        }
        break;

      default:
        return false;
    }
  };

  return (
    <>
      <Select
        placeholder="Group By"
        style={{
          width: 120,
        }}
        allowClear
        options={[
          {
            value: "Sku",
            label: "Sku",
          },
        ]}
        value={type === "sku" ? groupBy : batchGroupBy}
        onChange={(v) => handleGroupValue(v)}
      />
    </>
  );
};

export default GroupByFilter;
