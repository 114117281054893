import { memo, useEffect, useState } from "react";
import "./FilesFilterRow.scss";
// import NameFilter from "../Filters/NameFilter";
import {
  CheckOutlined,
  CheckSquareOutlined,
  ClearOutlined,
  CloseOutlined,
  FileDoneOutlined,
  FilterFilled,
  OrderedListOutlined,
  SaveFilled,
  SaveOutlined,
  TableOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Divider,
  Popconfirm,
  Space,
  Tooltip,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import StatusFilters from "./StatusFilters";
import NameFilter from "./NameFilter";
import DynamicDrawer from "../../../commonComponents/DynamicDrawer/DynamicDrawer";
import { pushBatchDetails } from "../../../ReduxComponents/Reducers/BatchReducer";
import DynamicFilters from "./DynamicFilters";
import { useFetchFilters } from "../../../Api/BatchesApi";
import DefaultButton from "../../../commonComponents/buttons/button";
import useFourNotOne from "../../../hooks/useFourNotOne";
import { useAcceptAll } from "../../../Api";
import SelectSize from "./SelectSize";
import ResolutionSwitch from "../../../commonComponents/ResolutionSwitch";
import GroupByFilter from "../../../commonComponents/GroupByFilter";

const FilesFilterRow = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [localSelectedFilters, setLocalSelectedFilters] = useState({});
  const {
    batchReducer: {
      batchId,
      filtersList,
      selectedFiltersList,
      searchName,
      statusFiltersOptions,
      currentPage,
      currentPageSize,
      batchList,
      batchStats,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { fourNotOne } = useFourNotOne();
  const { mutate: acceptCall } = useAcceptAll(AcceptSuccessfull, AcceptFail);

  function AcceptSuccessfull(data) {
    dispatch(pushBatchDetails({ isRefetchBatch: true }));
  }
  function AcceptFail(err) {
    console.log(err);
  }
  let checkCount = 0;
  let hasUnreviewed = batchStats
    ?.filter((elm) => elm?.stringStatus?.toLowerCase()?.includes("unreviewed"))
    .forEach((item) => {
      checkCount += item.count;
    });
  // const {
  // 	batchReducer: { viewType },
  // } = useSelector((state) => state);
  // const dispatch = useDispatch();
  // const handleViewType = (type) => {
  // 	dispatch(
  // 		pushBatchesDetails({
  // 			viewType: type,
  // 		})
  // 	);
  // };

  const getFiltersCount = () => {
    let selectedFiltersListCopy = { ...selectedFiltersList };
    let selectedItems = Object.values(selectedFiltersListCopy);

    let sumCount =
      (selectedItems?.length > 0 &&
        selectedItems.reduce((acc, item) => {
          return (acc = acc + item?.length);
        }, 0)) ||
      0;

    return sumCount;
  };

  const handleDrawerCancel = () => {
    setLocalSelectedFilters(selectedFiltersList);
    setDrawerOpen(false);
  };

  const handleDrawerSubmit = () => {
    dispatch(
      pushBatchDetails({
        selectedFiltersList: localSelectedFilters,
        currentPage: 1,
        scrollToIndex: 0,
      })
    );
    setDrawerOpen(false);
  };

  const handleDrawerClear = () => {
    setLocalSelectedFilters({});
    dispatch(
      pushBatchDetails({
        selectedFiltersList: {},
        currentPage: 1,
      })
    );
    setDrawerOpen(false);
  };

  const onSuccessFilters = (data) => {
    dispatch(
      pushBatchDetails({
        filtersList: data?.data?.filters,
      })
    );
  };

  const onErrorFilters = (error) => {
    fourNotOne(error);
    message.error(error?.response?.data?.message || "Oops, Please try again");
  };

  const { refetch: refetchFilters } = useFetchFilters(
    onSuccessFilters,
    onErrorFilters,
    batchId
  );

  const handleAcceptAll = (type) => {
    let finalObj = {
      batchId,
      filter: selectedFiltersList,
      searchName,
      statusFilter: statusFiltersOptions ?? [],
      pageNum: currentPage,
      pageSize: currentPageSize,
      type,
    };

    if (type.includes("all")) {
      delete finalObj.pageNum;
      delete finalObj.pageSize;
    }
    acceptCall(finalObj);
  };

  useEffect(() => {
    refetchFilters();
  }, [batchId]);

  return (
    <div className="files-filters-row-wrapper">
      {/* <NameFilter /> */}
      <ResolutionSwitch type={"batch"} />
      <Divider type="vertical" style={{ height: "2rem" }} />
      <SelectSize />
      <Divider type="vertical" style={{ height: "2rem" }} />
      <GroupByFilter type={"batch"} />
      <Divider type="vertical" style={{ height: "2rem" }} />
      <StatusFilters />
      <Divider type="vertical" style={{ height: "2rem" }} />
      <NameFilter />
      <Divider type="vertical" style={{ height: "2rem" }} />
      <Button
        className="filters-button"
        type="default"
        onClick={() => setDrawerOpen(true)}
      >
        <Tooltip title="Filters" placement="topLeft">
          <Badge
            placement="topRight"
            count={getFiltersCount()}
            overflowCount={99}
            color="geekblue"
            size="small"
          >
            <FilterFilled />
          </Badge>
        </Tooltip>
      </Button>
      <Divider type="vertical" style={{ height: "2rem" }} />
      <Tooltip title="Accept All">
        <Popconfirm
          placement="topRight"
          title={"Are you sure you want accept all?"}
          description={"Accept All"}
          onConfirm={() => handleAcceptAll("all")}
          okText="Yes"
          cancelText="No"
        >
          <DefaultButton
            text={<CheckOutlined style={{ fontSize: "1rem" }} />}
            type="default"
            style={{ padding: "2px 8px" }}
            disabled={batchList.length == 0 || checkCount == 0}
          />
        </Popconfirm>
      </Tooltip>
      <Divider type="vertical" style={{ height: "2rem" }} />
      <Tooltip title="Accept all on this page">
        <Popconfirm
          placement="topRight"
          title={"Are you sure you want accept all on this page?"}
          description={"Accept all on this page"}
          onConfirm={() => handleAcceptAll("onPage")}
          okText="Yes"
          cancelText="No"
        >
          <DefaultButton
            text={<FileDoneOutlined style={{ fontSize: "1rem" }} />}
            type="default"
            style={{ padding: "2px 8px" }}
            disabled={batchList.length == 0 || checkCount == 0}
          />
        </Popconfirm>
      </Tooltip>
      {drawerOpen && (
        <DynamicDrawer
          open={drawerOpen}
          onClose={handleDrawerCancel}
          extra={
            <Space>
              <Tooltip title="Cancel">
                <Button
                  type="text"
                  onClick={handleDrawerCancel}
                  style={{ padding: "0 4px" }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </Space>
          }
          maskClosable={true}
          title={"Filters"}
          className="dynamic-drawer-filters"
        >
          <DynamicFilters
            localSelectedFilters={localSelectedFilters}
            setLocalSelectedFilters={setLocalSelectedFilters}
          />
          <Space className="mt-1">
            <DefaultButton
              text="Apply"
              type="primary"
              clickHandler={handleDrawerSubmit}
            />
            <DefaultButton
              text="Clear"
              type="default"
              clickHandler={handleDrawerClear}
            />
          </Space>
        </DynamicDrawer>
      )}
    </div>
  );
};

export default memo(FilesFilterRow);
