import React, { useEffect, useState } from "react";
import { AutoSizer, List } from "react-virtualized";
import { Empty } from "antd";
import "react-virtualized/styles.css";
import FileThumbnail from "./FilesList/FileThumbnail";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchDetails } from "../../ReduxComponents/Reducers/BatchReducer";
import { updateFileDetails } from "../../ReduxComponents/Reducers/FileReducer";

const BatchGroupView = ({
  showHires,
  dynamicHW,
  groupedData,
  isDownloadOpen,
  handleDownloadList,
  selectedForDownload,
  statusDict,
  scrollToIndex,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const batchReducer = useSelector((state) => state.batchReducer);
  const [selectedFile, setSelectedFile] = useState(
    localStorage.getItem("batchScrollId")
  );
  const [scrolledIndex, setScrolledIndex] = useState("");
  
  useEffect(() => {
    if (selectedFile) {
      setTimeout(() => {
        const element = document.getElementById(selectedFile);
        console.log(element);
        if (element) {
          console.log("Scrolling to element:", element);
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          console.log("Element not found for id:", selectedFile);
        }
      }, 100);
    }
  }, [selectedFile, location]);
  useEffect(() => {
    if (selectedFile) {
      const index = groupedData.findIndex((group) =>
        group.data.some((file) => file._id === selectedFile)
      );
      if (index !== -1) {
        setScrolledIndex(index); 
      }
    }
  }, [selectedFile, groupedData, location]);
  const handleCardClick = (data) => {
    console.log(data?._id);
    localStorage.setItem("batchScrollId", data?._id);
    setSelectedFile(data?._id);
    if (isDownloadOpen) {
      handleDownloadList(data);
    } else {
      navigate(`${location?.pathname}?id=${data?._id}`, {
        state: {
          batchReducer,
        },
      });
      dispatch(
        pushBatchDetails({
          scrollToIndex: scrollToIndex,
        })
      );
      dispatch(updateFileDetails({ metaSwitch: false }));
    }
  };

  const rowRenderer = ({ index, key, style }) => {
    return (
      <div
        key={key}
        style={{
          ...style,
          paddingLeft: "5px",
          borderBottom: "1px solid #afafaf",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            marginTop: "5px",
          }}
          key={showHires}
        >
          {groupedData[index]?.data.map((objData, i) => {
            return (
              <FileThumbnail
                id={objData?._id} // Assigning the id to the FileThumbnail div
                data={objData}
                key={objData?._id || i}
                style={style}
                statusDict={statusDict}
                dynamicHW={dynamicHW}
                isDownloadOpen={isDownloadOpen}
                handleDownloadList={handleDownloadList}
                selectedForDownload={selectedForDownload}
                showHires={showHires}
                fileIndex={index}
                handleCardClick={handleCardClick}
                isBatch={true}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const imageCellSizeAndPositionGetter = ({ index, imageIndex }) => {
    const columnCount = 7;
    const rowPadding = 0;
    const imageWidth = dynamicHW?.width;
    const imageHeight = dynamicHW?.height - 12;
    const columnIndex = columnCount === 1 ? 0 : imageIndex % columnCount;
    const rowIndex = Math.floor(imageIndex / columnCount);
    const x = columnIndex * imageWidth;
    const y = rowIndex * (imageHeight + rowPadding);
    return {
      height: imageHeight,
      width: imageWidth,
      transform: `translate(${x}px, ${y}px)`,
    };
  };

  const calculateRowHeight = (rowData, containerWidth) => {
    const { index } = rowData;
    const numImages = groupedData[index]?.data.length;
    const imageHeight = dynamicHW?.height || 50;
    const rowPadding = 10;
    const imagesPerRow = Math.floor(containerWidth / dynamicHW?.width);
    const numRows = Math.ceil(numImages / imagesPerRow);
    return numRows * imageHeight;
  };

  return (
    <AutoSizer key={JSON.stringify(dynamicHW)}>
      {({ height, width }) => (
        <List
          width={width}
          height={height}
          rowCount={groupedData?.length}
          rowHeight={(d) => calculateRowHeight(d, width)}
          rowRenderer={rowRenderer}
          scrollToIndex={scrolledIndex}
          noContentRenderer={() => (
            <Empty
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "Center",
                alignItems: "center",
              }}
            />
          )}
        />
      )}
    </AutoSizer>
  );
};

export default BatchGroupView;
