import "./SkusList.scss";
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Empty,
  Image,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import {
  ExclamationOutlined,
  CheckOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { colorScheme } from "../../../utils/extra-utils";
import { pushSkusDetails } from "../../../ReduxComponents/Reducers/SkusReducer";
import DynamicModal from "../../../commonComponents/DynamicModal/DynamicModal";

const SkusThumbnail = ({
  data,
  key,
  style,
  fileIndex,
  dynamicHW,
  selectedForDownload,
  handleDownloadList,
  isDownloadOpen,
  showHires,
  handleCardClick,
  isSku,
  id
}) => {
  const skusReducer = useSelector((state) => state.skusReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoaded, SetIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const [showMeta, setShowMeta] = useState(false);

  const handleCardClicks = (data) => {
    if (isDownloadOpen) {
      handleDownloadList(data);
    } else {
      dispatch(pushSkusDetails({ scrollToIndex: fileIndex }));
      navigate(`${location?.pathname}?batch=${data?.batch}&id=${data?._id}`, {
        state: {
          skusReducer,
        },
      });
    }
  };

  const getSignFromSwitch = (sign) => {
    switch (sign) {
      case "exclamation":
        return (
          <ExclamationOutlined
            style={{
              //   width: "100%",
              //   height: "100%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
          />
        );
      case "check":
        return (
          <CheckOutlined
            style={{
              //   width: "100%",
              //   height: "100%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
          />
        );
      case "cross":
        return (
          <CloseOutlined
            style={{
              //   width: "100%",
              //   height: "100%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
          />
        );
      default:
        return <></>;
    }
  };

  const getSign = () => {
    const dataStatus = data?.status;
    const { color, sign } = colorScheme[dataStatus];

    return (
      <span
        style={{
          width: "90%",
          height: "90%",
          //   backgroundColor: color,
          //   display: "block",
          //   borderRadius: "50%",
        }}
        className="flex-center"
      >
        <Tooltip placement="topLeft" title={data?.stage}>
          {getSignFromSwitch(sign)}
        </Tooltip>
      </span>
    );
  };
  const renderMetaDetails = (type) => {
    let tempData = data?.metadata;

    if (tempData && Object?.keys(tempData)?.length > 0) {
      return (
        <Descriptions
          column={type == "modal" ? 3 : 1}
          size={type != "modal" ? "small" : "default"}
          className="py-1"
        >
          {Object?.keys(tempData)?.map((elm) => {
            if (typeof tempData[elm] == "string") {
              return (
                <Descriptions.Item
                  label={elm}
                  contentStyle={{ fontSize: "0.8rem" }}
                >
                  {tempData[elm]}
                </Descriptions.Item>
              );
            }
            // else if(typeof  data[elm] == 'object') {
            // 	Object.keys(data[elm]).map((iem))
            // }
          })}
        </Descriptions>
      );
    } else {
      return <Empty className={type == "modal" ? "itemCenter" : ""} />;
    }
  };
  const handleOpenMetaModal = (e) => {
    e.stopPropagation();
    setShowMeta(true);
  };
  return (
    <div className="skus-view-wrapper" key={key} id={id}>
      <Card
        hoverable
        style={{
          width: dynamicHW?.width - 5 + "px",
          height: dynamicHW?.height - 20 + "px",
          padding: 0,
        }}
        onClick={() => (isSku ? handleCardClick(data) : handleCardClicks(data))}
        className={`skus-card-main ${
          !!selectedForDownload.find(
            (el) => el?._id === data?.latestImage?.imagePath?._id
          )
            ? "selectedItem"
            : ""
        }`}
      >
        <div
          style={{
            width: "100%",
            height: "max-content",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!isLoaded && (
            <Skeleton.Image
              style={{
                height: dynamicHW?.height - 42 + "px",
                width: dynamicHW?.width - 17 + "px",
                objectFit: "contain",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="loader-class"
              active={true}
            />
          )}
          <img
            alt="example"
            src={
              showHires
                ? data?.latestImage?.imagePath?.url
                : data?.latestImage?.thumbPath?.url
            }
            style={{
              height: dynamicHW?.height - 42 + "px",
              width: dynamicHW?.width - 17 + "px",
              objectFit: "contain",
              margin: 0,
              display: isLoaded ? "block" : "none",
            }}
            onLoad={(e) => {
              SetIsLoaded(true);
            }}
          />
          {isLoaded && (
            <span
              style={{
                height: "22px",
                width: "22px",
                position: "absolute",
                top: "5px",
                left: "5px",
                backgroundColor:
                  colorScheme[data?.status] && colorScheme[data.status].color,
                //   display: "block",
                borderRadius: "50%",
              }}
              className="flex-center"
            >
              {getSign()}
            </span>
          )}

          {data?.metadata && (
            <Tooltip title="Details">
              <Button
                size="small"
                type="ghost"
                onClick={(e) => handleOpenMetaModal(e)}
                className="meta-info-btn"
              >
                <InfoCircleTwoTone />
              </Button>
            </Tooltip>
          )}
        </div>
        <div
          style={{
            width: "100%",
            height: "10%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title={data?.filename} placement="topLeft">
            <Typography.Text className="filename-typo">
              {data?.filename}
            </Typography.Text>
          </Tooltip>
        </div>
      </Card>
      {showMeta && (
        <DynamicModal
          open={showMeta}
          close={() => setShowMeta(false)}
          closable
          title="File Details"
          width="80%"
          height={data?.metadata ? "auto" : "400px"}
          customClass="metaModal"
          component={renderMetaDetails("modal")}
        />
      )}
    </div>
  );
};

export default SkusThumbnail;
