import { memo } from "react";
import "./FilesList.scss";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchDetails } from "../../../ReduxComponents/Reducers/BatchReducer";
import { Divider, Pagination, message } from "antd";
import ListView from "./ListView";
import { clearDownloadDetails } from "../../../ReduxComponents/Reducers/DownloadReducer";

const FilesList = () => {
	const {
		batchReducer: {
			totalCount,
			currentPage,
			currentPageSize,
			batchList,
			statusDict,
			scrollToIndex,
			selectedWidth,
			selectedHeight,
		},
		userReducer: { resetPasswordFlag },
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const showTotal = (total) => `Total Files:  ${total} `;
	const onShowSizeChange = (current, pageSize) => {
		if (pageSize !== currentPageSize) {
			dispatch(
				pushBatchDetails({
					currentPage: 1,
					currentPageSize: Number.parseInt(pageSize),
				})
			);
		} else if (current !== currentPage && current >= 1) {
			dispatch(
				pushBatchDetails({
					currentPage: Number.parseInt(current),
				})
			);
		}
		dispatch(
			clearDownloadDetails()
		)
	};

	return (
		<div className="files-list-wrapper">
			<div className="list-tabular-data-div">
				<ListView
					batchListData={batchList}
					statusDict={statusDict}
					scrollToIndex={scrollToIndex}
					selectedWidth={selectedWidth}
					selectedHeight={selectedHeight}
				/>
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className="pagination-div">
				<Pagination
					size="small"
					total={totalCount}
					showSizeChanger
					showTotal={showTotal}
					defaultCurrent={1}
					onChange={onShowSizeChange}
					responsive={true}
					current={currentPage}
					pageSize={currentPageSize}
					pageSizeOptions={[50, 100, 150, 200]}
					disabled={resetPasswordFlag}
				/>
			</div>
		</div>
	);
};

export default memo(FilesList);
